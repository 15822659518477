import React, { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons';
import axios from 'axios';
import { shownotification } from '../../utils/functions';


const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, {
                email: email,
            });

            console.log("forgot password response:", response);
            setLoading(false);
            await shownotification('success', 'Password Reset Requested', 'We have sent you an email with instructions to reset your password. Please check your inbox.');
            setTimeout(() => navigate("/login"), 2000);

        } catch (error) {
            console.log(error);
            setLoading(false);
            await shownotification('error', 'Password Reset failed', error.response.data.message);
        }
    };

    return (
        <>
            <Header />

            <section className="PaymentData clearfix">
                <div className="container">
                    <div className='auth-card'>
                        <h2 className='mb-4'>Forgot Password ?</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4 position-relative">
                                        <span className="icon-card"><MailOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between mb-4 fs-6'>
                                        <Link to="/login">Remembered Password ? Sign In</Link>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                                            {loading ? 'Continue ...' : 'Continue'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ForgotPassword