import React, { createContext, useEffect, useState } from "react";
import { isEmpty } from 'lodash';
import store from "../utils/store";
import useStorage from "../use/useStorage";
const ProfileContext = createContext();

const ProfileProvider = ({ children, _ }) => {
    const [cache] = useStorage({
        profile: null,
    });

    const [validatedProfile, setValidatedProfile] = useState("");

    const isActive = () => {
        return !isEmpty(validatedProfile);
    }

    const outOfCredits = () => {
        return validatedProfile?.user_credits === 0;
    }

    useEffect(() => {
        if (validatedProfile !== "") return;
        setValidatedProfile(cache.profile);
    }, [cache.profile])

    useEffect(() => {
        if (validatedProfile === "") return;
        store.set({
            profile: validatedProfile
        })
    }, [validatedProfile])

    return (
        <ProfileContext.Provider value={{
            isActive,
            validatedProfile,
            setValidatedProfile,
            outOfCredits
        }}>
            {children}
        </ProfileContext.Provider>
    );
};

const withProfile = (Child) => (props) => (
    <ProfileContext.Consumer>
        {(context) => <Child {...props} {...context} />}
    </ProfileContext.Consumer>
);

export { ProfileProvider, withProfile };    