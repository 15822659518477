import React from 'react';
import { getBackendImageUrl } from '../../services/API';

const Result = ({ imageUrl }) => {

    const handleDownload = async () => {
        console.log("Downloading image :", imageUrl);
        const backendUrl = getBackendImageUrl(imageUrl);

        try {
            const response = await fetch(backendUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch the image: ${response.statusText}`);
            }

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <div className='d-flex flex-column align-items-center'>
            <img src={imageUrl} alt="result" style={{ maxWidth: '100%', height: 'auto', width: '300px' }} />
            <button className='btn btn-primary mt-3' onClick={handleDownload}>Download Image</button>
        </div>
    );
};

export default Result;