import React, { useState } from 'react';
import { Avatar, Button, Drawer, Popover } from 'antd';
import { MenuOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
import { withProfile } from '../../contexts/profileContext';
import store from '../../utils/store';
import { confirmModal } from '../../utils/functions';

const Header = ({ isActive, validatedProfile }) => {
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleLogout = async () => {
        const title = 'Logout ?';
        const content = <div style={{ textAlign: 'center' }}>Are you sure you want to log out ?</div>;
        const confirmation = await confirmModal(title, content, 416);
        if (confirmation) {
            store.set({ profile: null });
            window.location.reload();
        }
    }

    const content = (
        <div className='account-info' style={{ width: 180 }}>
            <div className='d-flex flex-column align-items-center'>
                <Avatar size="large" icon={<UserOutlined />} />
                <span className='fw-bold mt-2'>{validatedProfile?.user_name}</span>
            </div>
            <hr />
            <div className="d-flex flex-column mt-3">
                <label>
                    <div className='fw-bold'>Account_Type :</div>
                    <span className="ml-3">{validatedProfile?.user_type}</span>
                </label>
                <label>
                    <div className='fw-bold'>Your_Credits :</div>
                    <span className="ml-3">{validatedProfile?.user_credits}</span>
                </label>
            </div>
            <hr />
            <div className='text-center'>
                <Button type='primary' danger onClick={handleLogout}><LogoutOutlined /> Logout</Button>
            </div>
        </div>
    );

    return (
        <header className="header">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid"> <Link className="navbar-brand" to="/"> <img src="assets/img/db174x48.png" alt="logo" /> </Link>
                    <div className="mobile-menu d-flex align-items-center">
                        {isActive() ?
                            <Popover content={content} trigger="click">
                                <button className="btn d-block d-lg-none gradient-btn mx-3"><UserOutlined /></button>
                            </Popover>
                            :
                            null
                        }
                        <button className="btn d-block d-lg-none bg-light" type="submit" onClick={() => setOpenDrawer(true)}><MenuOutlined /></button>
                    </div>
                    <div className="menuwrapp">
                        <div className="menutext d-flex align-items-center">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/">About</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/pricing">Pricing</Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/blogs">Blogs</Link></li>
                            </ul>
                            <div className="d-flex align-items-center buttonbg">
                                {isActive() ?
                                    <Popover content={content} trigger="click">
                                        <button className="btn gradient-btn"><UserOutlined /></button>
                                    </Popover>
                                    :
                                    <Link className="nav-link" to="/login"><button className="btn gradient-btn">Login</button></Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <Drawer title="Menu" onClose={() => setOpenDrawer(false)} open={openDrawer}>
                <ul className='d-flex flex-column align-items-center navbar-drawer-list'>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/pricing">Pricing</Link></li>
                    <li><Link to="/blogs">Blogs</Link></li>
                    {isActive() ?
                        null
                        :
                        <li><Link to="/login">login</Link></li>
                    }
                </ul>
            </Drawer>
        </header>
    )
}

export default withProfile(Header);