import React from 'react'
import HeroArea from './HeroArea'
import QualityChecker from './QualityChecker'
import WhatRemoval from './WhatRemoval'
import RemoveBackground from './RemoveBackground'
import BuiltForEveryone from './BuiltForEveryone'
import PricingPlan from './PricingPlan'
import FAQ from './FAQ'
import Review from './Review'
import AdvanceEditing from './AdvanceEditing'
import Header from '../Header'
import Footer from '../Footer'

const Home = () => {
    return (
        <>
            <Header />
            <HeroArea />
            <QualityChecker />
            <WhatRemoval />
            <RemoveBackground />
            <BuiltForEveryone />
            <PricingPlan />
            <FAQ />
            <Review />
            <AdvanceEditing />
            <Footer />
        </>
    )
}

export default Home