import React from 'react'

const FAQ = () => {
    return (
        <div className="faqwrapp">
            <div className="container">
                <div className="headcommon text-center">
                    <h2>Frequently Asked Questions</h2>
                    <p>Here, we have listed some of the commonly asked questions from the community. If you do not find the
                        information you need, feel free to reach out to us at <a href="mailto:info@deletebg.com">info@deletebg.com</a> </p>
                </div>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> What is deletebg.com? </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> What images are
                                supported? </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo"> What images are
                                supported? </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseTwo"> What is the maximum
                                image resolution / file size? </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseTwo"> How can I use bg
                                (background) remover for Ultra HD 4k photos to remove the background? </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseTwo"> Can I remove the
                                background of high resolution (hd) photos for free? </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseTwo"> How can I remove the
                                background of the 1080p image? </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <p> Deletebg is an AI-powered tool that automatically removes image backgrounds in a matter of seconds.
                                    With our bulk AI design tools, we help people, agencies, and e-commerce businesses all over the world
                                    enhance their productivity and revenue.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ