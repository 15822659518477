import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import AdvanceEditing from '../Home/AdvanceEditing'
import BuiltForEveryone from '../Home/BuiltForEveryone'
import { Link } from 'react-router-dom'

const Blogs = () => {
    return (
        <>
            <Header />

            <div className="relatedpost-wrapp pt-0">
                <div className="container">
                    <h3 className="text-center pb-3">DeleteBg Blog</h3>
                    <div className="blog-txt text-center">Bring you the best content to spark your inspiration.</div>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="relatedpostdiv"> <Link to="/blog-details">
                                <div className="relatedpostimg"> <img src="assets/img/relatedpostimg1.svg" alt="" /> </div>
                                <span>July 6, 2023</span>
                                <h5>Design Inspiration: What Colors Go Well with Blue?</h5>
                            </Link> </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="relatedpostdiv"> <Link to="/blog-details">
                                <div className="relatedpostimg"> <img src="assets/img/relatedpostimg2.svg" alt="" /> </div>
                                <span>July 11, 2022</span>
                                <h5>Deletebg VS Photoshop – Which  tool removes backgrounds the best?</h5>
                            </Link>
                            </div>
                        </div>
                        <div className="col-md-4  mb-4">
                            <div className="relatedpostdiv"> <Link to="/blog-details">
                                <div className="relatedpostimg"> <img src="assets/img/relatedpostimg3.svg" alt="" /> </div>
                                <span>May 17, 2023</span>
                                <h5>Design Inspiration: What Colors Go Well With Orange? </h5>
                            </Link> </div>
                        </div>
                        <div className="col-md-4  mb-4">
                            <div className="relatedpostdiv"> <Link to="/blog-details">
                                <div className="relatedpostimg"> <img src="assets/img/relatedpostimg1.svg" alt="" /> </div>
                                <span>July 6, 2023</span>
                                <h5>Design Inspiration: What Colors Go Well with Blue?</h5>
                            </Link> </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="relatedpostdiv"> <Link to="/blog-details">
                                <div className="relatedpostimg"> <img src="assets/img/relatedpostimg2.svg" alt="" /> </div>
                                <span>July 11, 2022</span>
                                <h5>Deletebg VS Photoshop – Which  tool removes backgrounds the best?</h5>
                            </Link>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="relatedpostdiv"> <Link to="/blog-details">
                                <div className="relatedpostimg"> <img src="assets/img/relatedpostimg3.svg" alt="" /> </div>
                                <span>May 17, 2023</span>
                                <h5>Design Inspiration: What Colors Go Well With Orange? </h5>
                            </Link> </div>
                        </div>
                    </div>
                </div>
            </div>

            <BuiltForEveryone />
            <AdvanceEditing />
            <Footer />
        </>
    )
}

export default Blogs