import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer text-center">
            <div className="container">
                <div className="mb-5"> <Link to="/"><img src="assets/img/db378x104.png" alt="logo" /></Link> </div>
                <p className='pb-5'>Free and 100% Automatic bg removal! One-click auto background removal in just a couple of seconds! Our state
                    of the art AI-tools lets you remove backgrounds from product photos, eCommerce listings, selfies, profile
                    pictures and others without the cumbersome manual work. Remove the background on several images at the same
                    time, fine tune the result with our detailed cutout tools.</p>
                <div className="socialicon">
                    <h6>Find Us On</h6>
                    <div className="sociallink">
                        <Link to="/"><img src="assets/img/socialicon1.svg" alt="" /></Link>
                        <Link to="/"><img src="assets/img/socialicon2.svg" alt="" /></Link>
                        <Link to="/"><img src="assets/img/socialicon3.svg" alt="" /></Link>
                        <Link to="/"><img src="assets/img/socialicon4.svg" alt="" /></Link>
                    </div>
                </div>
                <hr />
                <div className='sociallink fs-6'>
                    <Link to="/terms-condition"><span>Terms & condition</span></Link>
                    <Link to="/refund-policy"><span>Refund Policy</span></Link>
                    <Link to="/privacy-policy"><span>Privacy Policy</span></Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer;