import React from 'react'

const QualityChecker = () => {
    return (
        <div className="comparisionslider bgsection text-center">
            <div className="container">
                <div className="headcommon text-center">
                    <h6>Quality Checker</h6>
                    <h2>Check Our <span>Stunning</span> Quality</h2>
                </div>
                <div className="options-area">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="people-tab" data-bs-toggle="tab" data-bs-target="#people" type="button" role="tab" aria-controls="people" aria-selected="true">People</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="products-tab" data-bs-toggle="tab" data-bs-target="#products" type="button" role="tab" aria-controls="products" aria-selected="false">Products</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="animals-tab" data-bs-toggle="tab" data-bs-target="#animals" type="button" role="tab" aria-controls="animals" aria-selected="false">Animals</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="cars-tab" data-bs-toggle="tab" data-bs-target="#cars" type="button" role="tab" aria-controls="cars" aria-selected="false">Cars</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="graphics-tab" data-bs-toggle="tab" data-bs-target="#graphics" type="button" role="tab" aria-controls="graphics" aria-selected="false">Graphics</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="people" role="tabpanel" aria-labelledby="people-tab">
                            <div className="tabimg">
                                <p>Slide to see how the background is removed with precision</p>
                                <img src="assets/img/comparisionsliderimg.svg" alt="" />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="products" role="tabpanel" aria-labelledby="products-tab">
                            <div className="tabimg">
                                <p>Slide to see how the background is removed with precision 2</p>
                                <img src="assets/img/comparisionsliderimg.svg" alt="" />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="animals" role="tabpanel" aria-labelledby="animals-tab">
                            <div className="tabimg">
                                <p>Slide to see how the background is removed with precision 3</p>
                                <img src="assets/img/comparisionsliderimg.svg" alt="" />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="cars" role="tabpanel" aria-labelledby="cars-tab">
                            <div className="tabimg">
                                <p>Slide to see how the background is removed with precision 4</p>
                                <img src="assets/img/comparisionsliderimg.svg" alt="" />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="graphics" role="tabpanel" aria-labelledby="graphics-tab">
                            <div className="tabimg">
                                <p>Slide to see how the background is removed with precision 5</p>
                                <img src="assets/img/comparisionsliderimg.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QualityChecker