import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import AdvanceEditing from '../Home/AdvanceEditing'
import { getPlans, getUserRegion } from '../../services/API'
import useStorage from '../../use/useStorage'
import store from '../../utils/store'
import { Link } from 'react-router-dom'
import { Spin } from 'antd'

const Pricing = () => {
    const [cache] = useStorage({
        userRegion: "",
        plans: [],
    })

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUserRegionAndPlans = async () => {
            setLoading(true);
            try {
                const regionResponse = await getUserRegion();
                const region = regionResponse.country;
                const plansResponse = await getPlans();
                const filteredPlans = plansResponse.filter(plan =>
                    region === "India" ? plan.region === "Indian" : plan.region === "International"
                );

                store.set({ userRegion: region, plans: filteredPlans });
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchUserRegionAndPlans();
    }, [cache.userRegion]);

    return (
        <>
            <Header />

            <section className="pricewrap clearfix">
                <div className="container">
                    <h2>Remove Background From Images in Bulk</h2>
                    <div className="blog-txt text-center">Take photo editing to the next level with an affordable background remover.</div>
                    <nav>
                        <div className="nav nav-tabs clearfix" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Monthly</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Annually</button>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <Spin spinning={loading}>
                                <div className="row">
                                    {cache.plans && cache.plans.map((plan, i) => {
                                        return (
                                            <div className="col-md-3" key={i}>
                                                <div className={`planscard ${plan.plan_name === "Basic" ? null : 'bg-light-blue'}`}>
                                                    <h6>{plan.plan_name}</h6>
                                                    <h3>{plan.monthly_price}{cache.userRegion === "India" ? <small>₹</small> : <small>$</small>}<span>/month</span> </h3>
                                                    <ul>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />{plan.credits} Credits </li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />{plan.storage} Storage</li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />15 GB Bandwidth</li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />45 Deletebg Transformations</li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />13.5K Basic Transformations</li>
                                                    </ul>
                                                    <p>No additional usage allowed over your monthly credits/storage.</p>
                                                    <div className="activatetxt"><Link to="/checkout">Sign Up Now</Link></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Spin>
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                            <Spin spinning={loading}>
                                <div className="row">
                                    {cache.plans && cache.plans.map((plan, i) => {
                                        return (
                                            <div className="col-md-3" key={i}>
                                                <div className={`planscard ${plan.plan_name === "Basic" ? null : 'bg-light-blue'}`}>
                                                    <h6>{plan.plan_name}</h6>
                                                    <h3>{plan.yearly_price}{cache.userRegion === "India" ? <small>₹</small> : <small>$</small>}<span>/year</span> </h3>
                                                    <ul>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />{plan.credits} Credits </li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />{plan.storage} Storage</li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />15 GB Bandwidth</li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />45 Deletebg Transformations</li>
                                                        <li><img src="assets/img/checkmarkicon.svg" alt="" />13.5K Basic Transformations</li>
                                                    </ul>
                                                    <p>No additional usage allowed over your monthly credits/storage.</p>
                                                    <div className="activatetxt"> <Link to="/checkout">Sign Up Now</Link> </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </Spin>
                        </div>
                    </div>
                </div>
            </section>
            {/* All plans include */}
            <section className="AllPlns clearfix">
                <div className="container">
                    <h2>All plans include</h2>
                    <div className="row text-center">
                        <div className="col-md-3 mb-3">
                            <div className="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width={56} height={27} viewBox="0 0 56 27" fill="none">
                                    <path d="M42.875 0C39.1847 0 35.8509 1.5225 33.4666 3.97359L30.2881 7.24063L33.7247 10.7723L36.9972 7.40797C38.488 5.87563 40.5727 4.92188 42.875 4.92188C47.3977 4.92188 51.0781 8.60234 51.0781 13.1261C51.0781 17.6488 47.3977 21.3292 42.875 21.3292C40.5727 21.3292 38.488 20.3755 36.9972 18.842C24.1417 5.62691 22.5525 3.99317 22.5334 3.97359C20.1491 1.5225 16.8153 0 13.125 0C5.87672 0 0 5.87672 0 13.1261C0 20.3744 5.87672 26.2511 13.125 26.2511C16.8153 26.2511 20.1491 24.7286 22.5334 22.2775L25.7119 19.0105L22.2753 15.4788L19.0028 18.842C17.512 20.3755 15.4273 21.3292 13.125 21.3292C8.60234 21.3292 4.92188 17.6488 4.92188 13.1261C4.92188 8.60234 8.60234 4.92188 13.125 4.92188C15.4273 4.92188 17.512 5.87563 19.0028 7.40797C19.5662 7.98722 33.3314 22.1386 33.4666 22.2775C35.8509 24.7286 39.1847 26.2511 42.875 26.2511C50.1233 26.2511 56 20.3744 56 13.1261C56 5.87672 50.1233 0 42.875 0Z" fill="url(#paint0_linear_23_1758)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_23_1758" x1={0} y1="13.1255" x2={56} y2="13.1255" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <p>Unlimited free previews on deletebg</p>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width={56} height={26} viewBox="0 0 56 26" fill="none">
                                    <path d="M10.0901 22.77L0 12.6799L10.0901 2.58917L13.5689 6.06796L6.9563 12.6799L13.5689 19.2918L10.0901 22.77Z" fill="url(#paint0_linear_23_1762)" />
                                    <path d="M45.0961 22.77L41.6179 19.2918L48.2299 12.6799L41.6179 6.06796L45.0961 2.58917L55.1868 12.6799L45.0961 22.77Z" fill="url(#paint1_linear_23_1762)" />
                                    <path d="M31.1827 0L35.5819 2.19929L24.0036 25.3565L19.6038 23.1565L31.1827 0Z" fill="url(#paint2_linear_23_1762)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_23_1762" x1={0} y1="12.6796" x2="13.5689" y2="12.6796" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_23_1762" x1="41.6179" y1="12.6796" x2="55.1868" y2="12.6796" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_23_1762" x1="19.6038" y1="12.6782" x2="35.5819" y2="12.6782" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <p>50 free previews via API and apps per month</p>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                                    <g clipPath="url(#clip0_23_1767)">
                                        <path d="M0 27.817L4.18306 32L24.3863 11.7968L20.2033 7.61371L0 27.817ZM4.18306 29.33L2.67001 27.817L14.8632 15.6238L16.3762 17.1369L4.18306 29.33ZM17.7113 15.8018L16.1982 14.2888L20.2033 10.2837L21.7164 11.7967L17.7113 15.8018Z" fill="url(#paint0_linear_23_1767)" />
                                        <path d="M9.39053 12.9971L11.6881 8.79614L15.889 6.49856L11.6881 4.20099L9.39053 6.10352e-05L7.09296 4.20099L2.89209 6.49856L7.09296 8.79614L9.39053 12.9971ZM8.4841 5.59207L9.39059 3.93466L10.2971 5.59207L11.9545 6.49856L10.2971 7.40506L9.39059 9.06247L8.4841 7.40506L6.82669 6.49856L8.4841 5.59207Z" fill="url(#paint1_linear_23_1767)" />
                                        <path d="M26.5085 10.9833L28.45 7.43319L32.0001 5.49163L28.45 3.55007L26.5085 0L24.5669 3.55007L21.0168 5.49163L24.5669 7.43319L26.5085 10.9833ZM25.958 4.94115L26.5085 3.93472L27.059 4.94115L28.0654 5.49163L27.059 6.04211L26.5085 7.04854L25.958 6.04211L24.9516 5.49163L25.958 4.94115Z" fill="url(#paint2_linear_23_1767)" />
                                        <path d="M28.45 18.1506L26.5085 14.6005L24.5669 18.1506L21.0168 20.0922L24.5669 22.0337L26.5085 25.5838L28.45 22.0337L32.0001 20.0922L28.45 18.1506ZM27.059 20.6426L26.5085 21.6491L25.958 20.6426L24.9516 20.0922L25.958 19.5417L26.5085 18.5352L27.059 19.5417L28.0654 20.0922L27.059 20.6426Z" fill="url(#paint3_linear_23_1767)" />
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_23_1767" x1={0} y1="19.8069" x2="24.3863" y2="19.8069" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_23_1767" x1="2.89209" y1="6.49856" x2="15.889" y2="6.49856" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_23_1767" x1="21.0168" y1="5.49163" x2="32.0001" y2="5.49163" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_23_1767" x1="21.0168" y1="20.0922" x2="32.0001" y2="20.0922" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <clipPath id="clip0_23_1767">
                                            <rect width={32} height={32} fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p>Deletebg for Adobe Photoshop</p>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width={32} height={31} viewBox="0 0 32 31" fill="none">
                                    <path d="M29.1875 0H2.8125C1.26169 0 0 1.26169 0 2.8125V21.875C0 23.4258 1.26169 24.6875 2.8125 24.6875H12.2624L11.4381 28.3385H7.48338V30.2135H24.5166V28.3385H20.5619L19.7376 24.6875H29.1875C30.7383 24.6875 32 23.4258 32 21.875V2.8125C32 1.26169 30.7383 0 29.1875 0ZM2.8125 1.875H29.1875C29.7044 1.875 30.125 2.29556 30.125 2.8125V17.8125H1.875V2.8125C1.875 2.29556 2.29556 1.875 2.8125 1.875ZM17.8154 24.6875L18.6397 28.3385H13.3603L14.1846 24.6875H17.8154ZM29.1875 22.8125H2.8125C2.29556 22.8125 1.875 22.3919 1.875 21.875V19.6875H30.125V21.875C30.125 22.3919 29.7044 22.8125 29.1875 22.8125Z" fill="url(#paint0_linear_23_1774)" />
                                    <path d="M9.05396 12.337L15.3655 6.02541L16.6911 7.35103L10.3796 13.6626L9.05396 12.337Z" fill="url(#paint1_linear_23_1774)" />
                                    <path d="M15.3032 12.3376L21.6148 6.02602L22.9404 7.35165L16.6288 13.6632L15.3032 12.3376Z" fill="url(#paint2_linear_23_1774)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_23_1774" x1={0} y1="15.1067" x2={32} y2="15.1067" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_23_1774" x1="9.71677" y1="12.9998" x2="16.0283" y2="6.68822" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_23_1774" x1="15.966" y1="13.0004" x2="22.2776" y2="6.68883" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#2C3C87" />
                                            <stop offset={1} stopColor="#07A0E4" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <p>Deletebg for Windows / Mac / Linux</p>
                        </div>
                    </div>
                </div>
            </section>

            <AdvanceEditing />
            <Footer />
        </>
    )
}

export default Pricing