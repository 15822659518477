import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import { useLocation } from 'react-router-dom';
import useStorage from '../../use/useStorage';
import { getPlans } from '../../services/API';
import { withProfile } from '../../contexts/profileContext';
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import axios from 'axios';
import { load } from "@cashfreepayments/cashfree-js";

const PaymentProcess = ({ validatedProfile }) => {
    const [cache] = useStorage({
        userRegion: "",
        profile: null
    })

    const [plan, setPlan] = useState("");
    const [planPrice, setPlanPrice] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");

    const location = useLocation();
    const data = location.state

    useEffect(() => {
        const fetchPlanAndPrice = async () => {
            try {
                const plansResponse = await getPlans();
                const getPlanDetail = plansResponse.filter(plan =>
                    cache.userRegion === "India" ? plan.region === "Indian" : plan.region === "International"
                ).filter(plan => plan.plan_name === data.selectedPlan);
                setPlan(getPlanDetail[0])
                setPlanPrice(data.isMonthly ? getPlanDetail[0].monthly_price : getPlanDetail[0].yearly_price)

            } catch (error) {
                console.error(error);
            }
        };

        fetchPlanAndPrice();
    }, [cache.userRegion, data]);

    useEffect(() => {
        setName(validatedProfile?.user_name || "");
        setEmail(validatedProfile?.user_email || "");
    }, [validatedProfile])

    const handlePayment = async () => {

        const missingFields = [];
        if (!plan) missingFields.push("Plan");
        if (!planPrice) missingFields.push("Plan Price");
        if (!name) missingFields.push("Name");
        if (!email) missingFields.push("Email");
        if (!mobile) missingFields.push("Mobile");

        if (missingFields.length > 0) {
            alert(`Please fill in the following fields: ${missingFields.join(", ")}`);
            return;
        }

        const orderDetails = {
            orderId: `ORDER-${Date.now()}`,
            orderAmount: planPrice,
            orderCurrency: plan.region === "Indian" ? 'INR' : 'USD',
            customerId: validatedProfile?.user_id.toString(),
            customerName: name,
            customerEmail: email,
            customerPhone: mobile,
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/create-order`, orderDetails);
            console.log("Order Created...", response);

            let cashfree;
            var initializeSDK = async function () {
                cashfree = await load({
                    mode: "sandbox" // or production
                });
            }
            await initializeSDK();

            let checkoutOptions = {
                paymentSessionId: response.data.paymentSessionId,
                redirectTarget: "_blank",
            };

            cashfree.checkout(checkoutOptions);

        } catch (error) {
            console.error("Error creating order:", error);
        }
    };

    return (
        <>
            <Header />

            <section className="PaymentData clearfix">
                <div className='container'>
                    <div className="d-flex">
                        <div style={{ flex: 1.5 }}>
                            <h3>Billing Details</h3>
                            <div className="col-md-12 col-12 mb-3 position-relative">
                                <input type="text" className="form-control" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} />
                                <UserOutlined style={{ position: 'absolute', top: 0, bottom: 0, color: '#000', paddingLeft: 15 }} />
                            </div>
                            <div className="col-md-12 col-12 mb-3 position-relative">
                                <input type="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} disabled />
                                <MailOutlined style={{ position: 'absolute', top: 0, bottom: 0, color: '#000', paddingLeft: 15 }} />
                            </div>
                            <div className="col-md-12 col-12 mb-3 position-relative">
                                <input type="tel" pattern='[0-9]' className="form-control" placeholder="Mobile Number" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                <PhoneOutlined style={{ position: 'absolute', top: 0, bottom: 0, color: '#000', paddingLeft: 15 }} />
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <h3>Order Details</h3>
                            <div className="planscard bg-light-blue">
                                <h6>{plan.plan_name}</h6>
                                <h3>{data.isMonthly ? plan.monthly_price : plan.yearly_price}{cache.userRegion === "India" ? <small>₹</small> : <small>$</small>}<span>/{data.isMonthly ? "month" : "year"}</span> </h3>
                                <ul>
                                    <li><img src="assets/img/checkmarkicon.svg" alt="" />{plan.credits} Credits </li>
                                    <li><img src="assets/img/checkmarkicon.svg" alt="" />{plan.storage} Storage</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="check1" name="option1" defaultValue="something" />
                            <label className="form-check-label">You acknowledge the terms of the service</label>
                        </div>
                    </div>
                    <div className="col-md-12 col-12 mb-3">
                        <button className="pay-btn w-100" onClick={handlePayment}>Buy Now</button>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default withProfile(PaymentProcess);