import React from 'react'

const RemoveBackground = () => {
    return (
        <div className="howtowrapp bgsection">
            <div className="container">
                <div className="headcommon text-center">
                    <h2>How to <span>remove background </span> from <span>Image</span>? </h2>
                    <p>Our online video background remover tool is an easy-to-use and efficient solution for removing unwanted backgrounds from your Image. With just a few clicks, you can remove distracting elements from your image and replace them with a solid color or a new background of your choice. Try it now and take your creations to the next level!</p>
                </div>
                <div className="row howtorow">
                    <div className="col-md-4">
                        <div className="howsec text-center"> <span className="notxt">1</span>
                            <h2>Select Your <span>Image File</span> </h2>
                            <p>Click the ‘Upload image’ button from your own system.</p>
                            <div className="howtoimg"> <img src="assets/img/howtoimg1.svg" alt="" /> </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="howsec text-center"> <span className="notxt">2</span>
                            <h2>Remove Image <span>Background Online</span></h2>
                            <p>Select your image track, click on effects, and then click on remove background.</p>
                            <div className="howtoimg"> <img src="assets/img/howtoimg2.svg" alt="" /> </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="howsec text-center"> <span className="notxt">3</span>
                            <h2>Download &amp; Share <span>for Free</span> </h2>
                            <p>In just a few clicks you have removed the background from your image, ready to be used. Download it and use it for free.</p>
                            <div className="howtoimg"> <img src="assets/img/howtoimg3.svg" alt="" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoveBackground