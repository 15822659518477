import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from './components/Home'
import Blogs from './components/Blogs';
import BlogDetails from './components/BlogDetails';
import Error from './components/Error';
import Pricing from './components/Pricing';
import Checkout from './components/Checkout';
import PaymentProcess from './components/PaymentProcess';
import Login from './Auth/Login';
import Signup from './Auth/Signup';
import ForgotPassword from './Auth/ForgotPassword';
import ScrollToTop from './ScrollToTop';
import PaymentStatus from './components/PaymentStatus';

const App = () => {

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blog-details" element={<BlogDetails />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="payment-process" element={<PaymentProcess />} />
        <Route path="payment-status/:order_id" element={<PaymentStatus />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  )
}

export default App