import axios from 'axios';

export const getCurrentTimestamp = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const getOutputURL = async (access_token) => {
    const processedImageName = 'image_' + Date.now() + '.png';
    localStorage.setItem('processedImageName', processedImageName);
    const url = 'https://api.dropboxapi.com/2/files/get_temporary_upload_link';
    const data = {
        commit_info: {
            path: `/Image_processed/${processedImageName}`,
            mode: 'add',
            autorename: true,
            mute: false,
            strict_conflict: false
        },
        duration: 3600
    };

    const headers = {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(url, data, { headers });
        console.log('Temporary outputURL link:', response, getCurrentTimestamp());
        return response.data.link;
    } catch (error) {
        console.error('Error creating temporary link:', error)
    }
}

export const getTemporaryLink = async (access_token, filePath) => {
    const url = 'https://api.dropboxapi.com/2/files/get_temporary_link';
    const headers = {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json'
    };
    const data = {
        path: filePath
    };

    try {
        const response = await axios.post(url, data, { headers });
        console.log('Temporary inputURL link:', response.data.link, getCurrentTimestamp());
        return response.data.link;
    } catch (error) {
        console.error('Error creating temporary link:', error.response.data);
    }
};

export const uploadFileToDropbox = async (access_token, imageData, uniqueFilename) => {
    const url = 'https://content.dropboxapi.com/2/files/upload';
    const headers = {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/octet-stream',
        'Dropbox-API-Arg': JSON.stringify({
            path: `/Image_uploaded/${uniqueFilename}`,
            mode: 'add',
            autorename: true,
            mute: false
        })
    };

    try {
        const response = await axios.post(url, imageData, { headers });
        console.log('Image uploaded successfully:', response.data, getCurrentTimestamp());
        return response.data.path_display;
    } catch (error) {
        console.error('Error uploading image:', error.response.data);
        throw error;
    }
};

export const getFileMetadata = async (access_token) => {
    const folderPath = '/Image_processed';
    const processedImageName = localStorage.getItem('processedImageName');

    const url = 'https://api.dropboxapi.com/2/files/list_folder';
    const data = {
        path: folderPath
    };

    const headers = {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(url, data, { headers });
        const files = response.data.entries;
        const file = files.find(f => f.name.includes(processedImageName));
        return file ? file.path_lower : null;
    } catch (error) {
        console.error('Error getting file metadata:', error);
    }
};

export const getSharedLink = async (access_token, filePath) => {
    const url = 'https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings';
    const data = {
        path: filePath,
        settings: {
            requested_visibility: 'public'
        }
    };

    const headers = {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(url, data, { headers });
        return response.data.url.replace('www.dropbox.com', 'dl.dropboxusercontent.com').replace('?dl=0', '');
    } catch (error) {
        console.error('Error creating shared link:', error);
    }
};