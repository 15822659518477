import React from 'react'

const Review = () => {
    return (
        <div className="reviewwrapp bgsection">
            <div className="container">
                <div className="headcommon text-center">
                    <h2>What people have to say about <span>DeleteBg</span> </h2>
                </div>
                <div className="fadeOut owl-carousel owl-theme">
                    <div className="item text-center">
                        <div className="reviewicon d-flex justify-content-center"> <span><img src="assets/img/staricon.svg" alt="" /></span>
                            <span><img src="assets/img/staricon.svg" alt="" /></span> <span><img src="assets/img/staricon.svg" alt="" /></span>
                            <span><img src="assets/img/staricon.svg" alt="" /></span> <span><img src="assets/img/staricon.svg" alt="" /></span>
                        </div>
                        <div className="reviewheading">
                            <h6>Suitable photos for my website</h6>
                            <p>I love being able to remove backgrounds from my pictures. I sell online, and when I take pictures, it's
                                not always in a clean, uncluttered environment. I like how Deletebg gets rid of all of that and allows
                                me to change the background to something more pleasing to the eye and suitable to place on my website.
                            </p>
                            <h2>Sue Darte</h2>
                        </div>
                    </div>
                    <div className="item text-center">
                        <div className="reviewicon d-flex justify-content-center"> <span><img src="assets/img/staricon.svg" alt="" /></span>
                            <span><img src="assets/img/staricon.svg" alt="" /></span> <span><img src="assets/img/staricon.svg" alt="" /></span>
                            <span><img src="assets/img/staricon.svg" alt="" /></span> <span><img src="assets/img/staricon.svg" alt="" /></span>
                        </div>
                        <div className="reviewheading">
                            <h6>Suitable photos for my website</h6>
                            <p>I love being able to remove backgrounds from my pictures. I sell online, and when I take pictures, it's
                                not always in a clean, uncluttered environment. I like how Deletebg gets rid of all of that and allows
                                me to change the background to something more pleasing to the eye and suitable to place on my website.
                            </p>
                            <h2>Sue Darte</h2>
                        </div>
                    </div>
                    <div className="item text-center">
                        <div className="reviewicon d-flex justify-content-center"> <span><img src="assets/img/staricon.svg" alt="" /></span>
                            <span><img src="assets/img/staricon.svg" alt="" /></span> <span><img src="assets/img/staricon.svg" alt="" /></span>
                            <span><img src="assets/img/staricon.svg" alt="" /></span> <span><img src="assets/img/staricon.svg" alt="" /></span>
                        </div>
                        <div className="reviewheading">
                            <h6>Suitable photos for my website</h6>
                            <p>I love being able to remove backgrounds from my pictures. I sell online, and when I take pictures, it's
                                not always in a clean, uncluttered environment. I like how Deletebg gets rid of all of that and allows
                                me to change the background to something more pleasing to the eye and suitable to place on my website.
                            </p>
                            <h2>Sue Darte</h2>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <div className="buttonbg gradient-btn"> <a href="#">View All Reviews</a> </div>
                </div>
            </div>
        </div>
    )
}

export default Review