import React from 'react'

const AdvanceEditing = () => {
    return (
        <div className="getstar-wrapp">
            <div className="container">
                <div className="getdiv d-flex">
                    <div className="getlft">
                        <h4>For Advanced Editing Try the DeleteBG </h4>
                        <p>It is power-packed with 5000+ customizable templates, image stock footage from iStock, filters, and everything else you need. Try Deletebg full-featured image editor.</p>
                        <a href="#">Get Started - It’s Free!</a>
                    </div>
                    <div className="getryt">
                        <div className="getrytimg"> <img src="assets/img/grtrytimg.svg" alt="" /> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdvanceEditing