import { useEffect, useRef, useState } from "react";
import store from "../utils/store";

const useStorage = (obj) => {
    const init = store.get(obj);
    const [value, setValue] = useState(init);

    // Use to ensure we don't set render state after unmounted
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        const listeners = [];

        for (const key in obj) {
            const id = store.watch(key, (value) => {
                if (isMounted.current) {
                    setValue((val) => ({
                        ...val,
                        [key]: value,
                    }));
                }
            });
            listeners.push({ id, key });
        }

        return () => {
            isMounted.current = false;
            listeners.forEach(({ id, key }) => store.unwatch(id, key));
        };
    }, []);

    return [value, store.set];
};

export default useStorage;