import React from 'react'

const BuiltForEveryone = () => {
    return (
        <div className="builtwrapp">
            <div className="container">
                <div className="headcommon text-center">
                    <h2>Built for <span>Everyone </span></h2>
                    <p>Whether you work in the creative space or in tech, Erase.bg will help you save a lot of time</p>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="detaildiv"> <em><img src="assets/img/icon1.svg" alt="" /></em>
                            <h5>Individuals</h5>
                            <p>Background removal was never this easy. Get the power of professional photo editors</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="detaildiv"> <em><img src="assets/img/icon2.svg" alt="" /></em>
                            <h5>Professionals</h5>
                            <p>Wasting too much time in photo editing? Use our single click solution</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="detaildiv"> <em><img src="assets/img/icon3.svg" alt="" /></em>
                            <h5>Developers</h5>
                            <p>Integrate our API anywhere. Add stunning background removal in your app to power your user’s photo editing needs</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="detaildiv"> <em><img src="assets/img/icon4.svg" alt="" /></em>
                            <h5>e-Commerce</h5>
                            <p>Get your product images ready instantly for any E-Commerce platforms</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="detaildiv"> <em><img src="assets/img/icon5.svg" alt="" /></em>
                            <h5>Media Persons</h5>
                            <p>Unfold your creative edge and make stunning content for your social media and websites</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="detaildiv"> <em><img src="assets/img/icon6.svg" alt="" /></em>
                            <h5>Enterprise</h5>
                            <p>Process thousands of high quality images quickly with bulk image support</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuiltForEveryone