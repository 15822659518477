import React from 'react'

const WhatRemoval = () => {
    return (
        <div className="candowrapp">
            <div className="container">
                <div className="headcommon text-center">
                    <h2>What Removal. <span>AI</span> Can Do For You... </h2>
                    <p>Remove Background for profile pictures, social media graphics, eSignatures, company logos, presentations and more using our smart editing, resizing and exporting tools</p>
                </div>
                <div className="row reverseborder">
                    <div className="col-md-6">
                        <div className="candotxt">
                            <h4>Access to Change Background Color and Image Backgrounds</h4>
                            <p>Free and 100% Automatic bg removal! One-click auto background removal in just a couple of seconds! Our state of the art AI-tools lets you remove backgrounds from product photos, eCommerce listings, selfies, profile pictures and others without the cumbersome manual work. </p>
                            <p>Remove the background on several images at the same time, fine tune the result with our detailed cutout tools.</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="candoimg"> <img src="assets/img/candoimg1.svg" alt="" /> </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="candoimg"> <img src="assets/img/candoimg2.svg" alt="" /> </div>
                    </div>
                    <div className="col-md-6">
                        <div className="candotxt d-flex align-content-center flex-wrap">
                            <h4>100% Automatically, without a single click</h4>
                            <p>No need to pick pixels, select colors, paint masks or move paths: Unscreen analyzes your video 100%automatically and generates a high-quality result. Simple, good.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatRemoval