import { ProfileProvider } from "./profileContext";

const Providers = ({ children }) => {
    return (
        <ProfileProvider>
            {children}
        </ProfileProvider>
    )
}

export default Providers;