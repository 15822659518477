import React from 'react'

const PricingPlan = () => {
    return (
        <div className="pricingplan-wrapp bgsection">
            <div className="container">
                <div className="pricingplandiv d-flex align-items-center">
                    <div className="headcommon m-0">
                        <h6>Pricing Plans</h6>
                        <h2>Our Affordable<span> Pricing Plans </span>For Your Project</h2>
                        <p>3D opens a new door for creativity. A solid set of features that will help you create your top best
                            designs with us.</p>
                    </div>
                    <div className="planscard">
                        <div className="mostpopuler positiona"> <img src="assets/img/mostpopular.svg" alt="" /> </div>
                        <h6>Premium</h6>
                        <h3>$35<span>/month</span> </h3>
                        <ul>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> All Premium Features </li>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> Unlimited Tutorial </li>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> Dual Account For Both </li>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> 24/7 Support </li>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> Live class Name Facilities </li>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> Unlimited Cloud Space </li>
                            <li> <img src="assets/img/checkmarkicon.svg" alt="" /> And Many More </li>
                        </ul>
                        <div className="activatetxt"> <a href="#">Activate Package</a> </div>
                    </div>
                    <div className="planscard planscardpink">
                        <div className="whitebg"> <img src="assets/img/whitebg.svg" alt="" /> </div>
                        <h6>Standard</h6>
                        <h3>$25<span>/month</span> </h3>
                        <ul>
                            <li> <img src="assets/img/checkmarkicondark.svg" alt="" /> All Standard Features </li>
                            <li> <img src="assets/img/checkmarkicondark.svg" alt="" /> 10 Tutorial Per Month </li>
                        </ul>
                        <div className="activatetxt"> <a href="#">Activate Package</a> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingPlan