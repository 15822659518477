import { Modal, notification } from "antd";
import { defaultCache } from "../constants/cache";
import store from "./store";

const encodeData = (data) => {
    return btoa(JSON.stringify(data));
};

const decodeData = (data) => {
    try {
        return JSON.parse(atob(data));
    } catch (e) {
        return null;
    }
};

export const getVariables = (variables) => {
    return new Promise((resolve) => {
        const data = {};
        if (variables) {
            variables.forEach((variable) => {
                const item = localStorage.getItem(variable);
                data[variable] = item ? decodeData(item) : null;
            });
        } else {
            Object.keys(localStorage).forEach((key) => {
                const item = localStorage.getItem(key);
                data[key] = item ? decodeData(item) : null;
            });
        }
        resolve(data);
    });
};

export const setVariables = (variables) => {
    return new Promise((resolve) => {
        Object.keys(variables).forEach((key) => {
            localStorage.setItem(key, encodeData(variables[key]));
        });
        resolve();
    });
};

export const removeVariables = (keys) => {
    return new Promise((resolve) => {
        keys.forEach((key) => {
            localStorage.removeItem(key);
        });
        resolve();
    });
};

let _cache = {};

export const _cacheHelper = {
    set() { },
    get() { },
    _set(cache) { _cache = cache },
    _update(obj) {
        for (const key in obj) {
            _cache[key] = obj[key];
        }
    },
    _remove(keys) {
        for (const key of keys) {
            delete _cache[key];
        }
    },
};

const generateProductKey = () => {
    let tokens = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", chars = 5, segments = 4, keyString = "";
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    for (var i = 0; i < segments; i++) {
        var segment = "";
        for (var j = 0; j < chars; j++) { var k = getRandomInt(0, 35); segment += tokens[k]; }
        keyString += segment;
        if (i < (segments - 1)) { keyString += "-"; }
    }
    return keyString;
}

const initializeCache = async () => {
    const existingCache = await getVariables(null);
    console.log("Existing cache", existingCache);

    if (!existingCache.device_name) {
        defaultCache.device_name = generateProductKey();
        await setVariables(defaultCache);
    } else {
        Object.assign(defaultCache, existingCache);
    }

    console.log("Default cache", defaultCache);
    store.set(defaultCache);
};

initializeCache();

export const confirmModal = (title, content, width) => {
    return new Promise((resolve) => {
        Modal.confirm({
            icon: null,
            title: title,
            content: content,
            className: 'notification-modal',
            centered: true,
            maskClosable: false,
            okText: "Confirm",
            cancelText: "Cancel",
            width: width,
            onOk: () => {
                resolve(true);
            },
            onCancel: () => {
                resolve(false)
            },
        });
    })
}

export const notificationModal = (title, content, width) => {
    return new Promise((resolve) => {
        Modal.info({
            icon: null,
            title: title,
            content: content,
            className: 'notification-modal',
            centered: true,
            maskClosable: false,
            okText: "Close",
            width: width,
            onOk: () => {
                resolve(true);
            },
        });
    });
}

export const shownotification = async (type, message, description) => {
    notification[type]({
        message: <b>{message}</b>,
        description: description,
        placement: 'top',
        duration: 1.5
    })
}