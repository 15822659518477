import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { useNavigate } from 'react-router-dom'
import { getPlans } from '../../services/API'
import useStorage from '../../use/useStorage'
import { Radio } from 'antd'
import { shownotification } from '../../utils/functions'
import { withProfile } from '../../contexts/profileContext'

const Checkout = ({ isActive }) => {
    const [cache] = useStorage({
        userRegion: "",
    })

    const navigate = useNavigate();

    const [plans, setPlans] = useState([]);
    const [isMonthly, setIsMonthly] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState("");

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const plansResponse = await getPlans();
                const filteredPlans = plansResponse?.filter(plan =>
                    cache.userRegion === "India" ? plan.region === "Indian" : plan.region === "International"
                ).filter(plan => plan.plan_name !== "Free");

                setPlans(filteredPlans);
            } catch (error) {
                console.error(error);
            }
        };

        fetchPlans();
    }, [cache.userRegion]);

    const proceedToCheckout = () => {
        if (!isActive()) {
            return navigate("/login");
        }
        if (!selectedPlan) return shownotification("error", "Error", "Please Select a Plan to Checkout");
        navigate("/payment-process", { state: { selectedPlan, isMonthly } });
    }

    return (
        <>
            <Header />

            <div className="cardview clearfix">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3>Subscription Plan</h3>
                                </div>
                                <div className="card-body pricewrap">
                                    <div className="mb-3">
                                        <nav>
                                            <div className="nav nav-tabs clearfix" id="nav-tab" role="tablist">
                                                <button className={`nav-link ${isMonthly ? 'active' : null}`} onClick={() => setIsMonthly(true)}>Monthly</button>
                                                <button className={`nav-link ${isMonthly ? null : 'active'}`} onClick={() => setIsMonthly(false)}>Annually</button>
                                            </div>
                                        </nav>
                                        {isMonthly ?
                                            <Radio.Group onChange={(e) => setSelectedPlan(e.target.value)} value={selectedPlan} className='checkout-plans w-100 my-4'>
                                                <div className='d-flex flex-column gap-4'>
                                                    {plans && plans.map((plan, i) => {
                                                        return (
                                                            <Radio key={i} value={plan.plan_name} style={{ color: "#FFF", fontSize: 18 }}>
                                                                <div>{plan.plan_name}</div>
                                                                <div>{plan.monthly_price}{cache.userRegion === "India" ? <small>₹</small> : <small>$</small>}<span>/month</span></div>
                                                            </Radio>
                                                        )
                                                    })}
                                                </div>
                                            </Radio.Group>
                                            :
                                            <Radio.Group onChange={(e) => setSelectedPlan(e.target.value)} value={selectedPlan} className='checkout-plans w-100 my-4'>
                                                <div className='d-flex flex-column gap-4'>
                                                    {plans && plans.map((plan, i) => {
                                                        return (
                                                            <Radio key={i} value={plan.plan_name} style={{ color: "#FFF", fontSize: 18 }}>
                                                                <div>{plan.plan_name}</div>
                                                                <div>{plan.yearly_price}{cache.userRegion === "India" ? <small>₹</small> : <small>$</small>}<span>/year</span></div>
                                                            </Radio>
                                                        )
                                                    })}
                                                </div>
                                            </Radio.Group>
                                        }
                                    </div>
                                    <div className="text-center">
                                        <button className="paypal-btn" onClick={proceedToCheckout}>Proceed to Checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default withProfile(Checkout)