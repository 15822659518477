import _ from "lodash";
import { _cacheHelper, getVariables, setVariables } from "./functions";

class Store {
    constructor() {
        this.store = {};
        this.watchers = {};
        _cacheHelper.set = this.set.bind(this);
        _cacheHelper.get = this.get.bind(this);
    }

    async load() {
        this.store = await getVariables(null);
        _cacheHelper._set(_.cloneDeep(this.store));
    }

    get(obj, clone = true) {
        if (obj === null) return !clone ? this.store : _.cloneDeep(this.store);
        if (typeof obj === "string") return !clone ? this.store[obj] : _.cloneDeep(this.store[obj] || null);
        const res = {};
        for (const key in obj) {
            res[key] = this.store[key] === undefined ? obj[key] : this.store[key];
        }

        return !clone ? res : _.cloneDeep(res);
    }

    set(obj) {
        if (typeof obj === "object") {
            for (const key in obj) {
                this.store[key] = obj[key];
                this.callWatchers(key);
            }
            _cacheHelper._update(obj);
            setVariables(obj);
        }
    }

    callWatchers(key) {
        if (this.watchers[key]) {
            for (const event_id in this.watchers[key]) {
                this.watchers[key][event_id]?.(_.cloneDeep(this.store[key]));
            }
        }
    }

    watch(key, callback) {
        const id = `${Math.random()}-${Date.now()}`;
        this.watchers[key] = this.watchers[key] || {};
        this.watchers[key][id] = callback;
        return id;
    }

    unwatch(id, key) {
        if (this.watchers[key]?.[id]) delete this.watchers[key][id];
    }
}

const store = new Store();

export default store;