import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Providers from './contexts';
import store from './utils/store';

const main = async () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  await store.load();

  root.render(
    <React.StrictMode>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Providers >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Providers>
      </GoogleOAuthProvider>
    </React.StrictMode>
  );

  reportWebVitals();
}
main();
