import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';

const PaymentStatus = () => {
    const [status, setStatus] = useState(null);
    const { order_id } = useParams();

    useEffect(() => {
        const fetchStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-order`, {
                    params: { order_id },
                });
                console.log("order status: ", response);

                setStatus(response.data);
            } catch (error) {
                console.error('Error fetching payment status:', error);
            }
        };

        if (order_id) {
            fetchStatus();
        }
    }, [order_id]);

    if (!status) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Header />

            <h1>Payment Status</h1>
            <p>Status: {status.order_status}</p>
            <p>Amount: {status.order_amount}</p>

            <Footer />
        </>
    );
};

export default PaymentStatus;