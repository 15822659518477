import React, { useState } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { SmileOutlined, KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom'
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { withProfile } from '../../contexts/profileContext';
import { shownotification } from '../../utils/functions';
import { Spin } from 'antd';

const Login = ({ setValidatedProfile }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/signin`, {
                email: email,
                password: password,
            });

            console.log("login response:", response);
            setValidatedProfile(response.data.user);
            setLoading(false);
            await shownotification('success', "Welcome Back!", "You have successfully logged in. Enjoy your experience!");
            setTimeout(() => navigate("/"), 1500);

        } catch (error) {
            console.log(error);
            setLoading(false);
            await shownotification('error', 'Login failed', error.response.data.message);
        }
    };

    const handleGoogleLoginSuccess = async (googleUser) => {
        const credentialResponseDecoded = jwtDecode(googleUser.credential);
        const googleId = credentialResponseDecoded.sub;
        const email = credentialResponseDecoded.email;
        const name = credentialResponseDecoded.name;
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                email: email,
                name: name,
                googleId: googleId,
            });

            console.log("google login response:", response);
            setValidatedProfile(response?.data?.user);
            setLoading(false);
            await shownotification('success', "Welcome Back!", "You have successfully logged in. Enjoy your experience!");
            setTimeout(() => navigate("/"), 1500);

        } catch (error) {
            console.log(error);
            setLoading(false);
            await shownotification('error', 'Login failed', error.response?.data?.message);
        }
    };

    const responseGoogleFailure = (response) => {
        console.log("Google Login Failure:", response);
    };


    return (
        <Spin spinning={loading}>
            <Header />

            <section className="PaymentData clearfix">
                <div className="container">
                    <div className='auth-card'>
                        <h2 className='mb-4'><SmileOutlined /> Login</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3 text-center google-loginbar">
                                        <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                            text="Login with Google"
                                            onSuccess={handleGoogleLoginSuccess}
                                            onFailure={responseGoogleFailure}
                                            cookiePolicy={"single_host_origin"}
                                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                            logo_alignment='center'
                                            width={400}
                                            shape='pill'
                                        />
                                    </div>
                                    <p className='text-center'>or</p>
                                    <div className="mb-4 position-relative">
                                        <span className="icon-card"><MailOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email address"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-4 position-relative">
                                        <span className="icon-card"><KeyOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between mb-4 fs-6'>
                                        <Link to="/signup">Don't have an account? Sign Up</Link>
                                        <Link to="/forgot-password">Forgot Password?</Link>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </Spin>
    )
}

export default withProfile(Login);