import React, { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { UserAddOutlined, MailOutlined, LockOutlined, KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { shownotification } from '../../utils/functions';
import { Spin } from 'antd';

const Signup = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cfpassword, setCfPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== cfpassword) {
      return await shownotification('error', 'Error', 'Passwords do not match');
    }
    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
        email: email,
        name: name,
        password: password
      });

      console.log("Regsiter response:", response);
      setLoading(false);
      await shownotification('success', 'Registration successful!', 'Thank you for registering. Please check your email to verify your account.');

      setTimeout(() => navigate("/login"), 2000);

    } catch (error) {
      console.log(error);
      setLoading(false);
      await shownotification('error', 'Registration failed', error.response.data.message);
    }
  };

  return (
    <Spin spinning={loading}>
      <Header />

      <section className="PaymentData clearfix">
        <div className="container">
          <div className='auth-card'>
            <h2 className='mb-4'><UserAddOutlined /> Register</h2>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4 position-relative">
                    <span className="icon-card"><UserOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                    <input type="text" className="form-control" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
                  </div>
                  <div className="mb-4 position-relative">
                    <span className="icon-card"><MailOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                    <input type="email" className="form-control" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="mb-4 position-relative">
                    <span className="icon-card"><KeyOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                    <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <div className="mb-4 position-relative">
                    <span className="icon-card"><LockOutlined style={{ color: '#000', fontSize: 20 }} /></span>
                    <input type="password" className="form-control" placeholder="Confirm Password" value={cfpassword} onChange={(e) => setCfPassword(e.target.value)} required />
                  </div>
                  <div className="form-group form-check mb-4">
                    <input type="checkbox" className="form-check-input" id="terms" checked={isChecked} onChange={() => setIsChecked(!isChecked)} required />
                    <label className="form-check-label" htmlFor="terms">
                      I agree to the <Link to="/terms" className="text-primary">terms and conditions</Link>
                    </label>
                  </div>
                  <div className="mb-3">
                    <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                      {loading ? 'Registering...' : 'Register'}
                    </button>
                  </div>
                  <p className='text-center'>or</p>
                  <div className='text-end fs-6'>
                    <Link to="/login">Already have an account? Login</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Spin>
  )
}

export default Signup